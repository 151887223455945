/* Main navigation
========================================================================== */
.nav-lt,
.nav-lt-inner,
.nav-rt,
.nav-rt-inner{
  position: fixed;
  z-index: 4;
}

.nav-lt,
.nav-lt-inner{
  top: 15px;
  left: 15px;
  @media (min-width: 550px){
    top: 30px;
    left: 35px;
  }
}

.nav-rt,
.nav-rt-inner{
  top: 15px;
  right: 15px;
  @media (min-width: 550px){
    top: 30px;
    right: 35px;
  }
}

.nav-lb,
.nav-lb-inner,
.nav-rb,
.nav-rb-inner{
  position: fixed;
  z-index: 4;
}

.nav-lb,
.nav-lb-inner{
  bottom: 15px;
  left: 15px;
  @media (min-width: 550px){
    bottom: 30px;
    left: 35px;
  }
}

.nav-rb,
.nav-rb-inner{
  bottom: 15px;
  right: 15px;
  @media (min-width: 550px){
    bottom: 30px;
    right: 35px;
  }
}

.nav-center{
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 15px;

}


/* Main navigation-overlay
========================================================================== */
div.menu{
  position: fixed;
  width: 100%;
  height: 0;
  background: #000;
  color: #fff;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.5s, visibility 0s;
  z-index: -10;
  visibility: hidden;
}

div.menu.activate{
  opacity: 1;
  height: 100%;
  z-index: 999;
  visibility: visible;
  transition: opacity 0.5s, visibility 0s;
}

.menu-inner {
  display: flex;
  position: relative;
  overflow-y: auto;
  width: 100vw;
  min-height: 100%;
}

.menu-inner-nav{
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 6.2rem;
  z-index: 10;
}

@media (min-width: 550px){
  .menu-inner-nav{
    height: 9.6rem;
  }
}

.menu-footer{
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  height: 4.8rem;
  bottom: 0;
  position: fixed;
  margin-bottom: 4.8rem;
}

.copyright{
  width: calc(100% - 4.8rem);
  font-size: 16px;
  color: rgb(80, 80, 80);
  font-family: $font-sans;
}

// .view a::after{
//   content: '\02192';
// }

/* Scroll down button
========================================================================== */
.scroll-down{
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: fixed;
  bottom: 3.6rem;
  font-size: calc(28px);
  // @media (min-width: 550px){
  //   bottom: 3.6rem;
  // }

  // a::after{
  //   content: '\02022';
  //   // content: '\02193';
  // }
}

.scroll-down img {
  width: 1.8rem;
  -webkit-transition: 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transition: 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  @media (min-width: 550px){
      width: 3rem;
  }
}

.rotated {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg); /* IE 9 */
  -moz-transform: rotate(180deg); /* Firefox */
  -webkit-transform: rotate(180deg); /* Safari and Chrome */
  -o-transform: rotate(180deg); /* Opera */
  transition: 0.6s $ease;
}


.js-scroll-top {
  // position: fixed;
  // bottom: 40px;
  // right: 40px;
  // color: $black;
  z-index: 1000;
  &:after{
    content: '\02191';
    margin-left: 5px;
  }
}
