/*

    OPACITY
    Docs: http://tachyons.io/docs/themes/opacity/

*/

.o-100 { opacity: 1;    }
.o-90  { opacity: .9;   }
.o-80  { opacity: .8;   }
.o-70  { opacity: .7;   }
.o-60  { opacity: .6;   }
.o-50  { opacity: .5;   }
.o-40  { opacity: .4;   }
.o-30  { opacity: .3;   }
.o-20  { opacity: .2;   }
.o-10  { opacity: .1;   }
.o-05  { opacity: .05;  }
.o-025 { opacity: .025; }
.o-0   { opacity: 0; }
