@font-face {
  font-family: 'Gestalt-Regular';
  src: url('../fonts/Gestalt-Regular.woff');
}

@font-face {
  font-family: 'Neuzeit';
  src: url('../fonts/NeuzeitSLTStd-Book.woff');
  font-weight: bold;
}

@font-face {
  font-family: 'Roobert';
  src: url('../fonts/RoobertTRIAL-Regular.woff');
}

@import "base/index";

$ease: cubic-bezier(0.190, 1.000, 0.220, 1.000);

// ::-webkit-scrollbar {
//   display: none;
// }

* {
  margin: 0;
  padding: 0;
  font: inherit;
  text-decoration: inherit;
  box-sizing: border-box;
  border: none;
  color: inherit;
  appearance: none;
  border-radius: 0;

  &:focus {
    outline: 0;
  }
}

/* Base Styles */
html {
  font-size: 62.5%; }
body {
  font-size: calc(14px + 0.5vw);
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  letter-spacing: 0.04em;
  word-spacing: 0.001em;
  line-height: 1.3;
  font-weight: 400;
  font-family: $font-sans;
  background-color: $black;
  color: $white;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

@import 'components/index';
@import 'tachyons/tachyons';
