/* 
  
   SKINS:PSEUDO

   Customize the color of an element when
   it is focused or hovered over.
 
 */

.hover-black:hover, 
.hover-black:focus { color: var(--black); }
.hover-near-black:hover, 
.hover-near-black:focus { color: var(--near-black); }
.hover-dark-gray:hover, 
.hover-dark-gray:focus { color: var(--dark-gray); }
.hover-mid-gray:hover, 
.hover-mid-gray:focus { color: var(--mid-gray); }
.hover-gray:hover, 
.hover-gray:focus { color: var(--gray); }
.hover-silver:hover, 
.hover-silver:focus { color: var(--silver); }
.hover-light-silver:hover, 
.hover-light-silver:focus { color: var(--light-silver); }
.hover-moon-gray:hover, 
.hover-moon-gray:focus { color: var(--moon-gray); }
.hover-light-gray:hover, 
.hover-light-gray:focus { color: var(--light-gray); }
.hover-near-white:hover, 
.hover-near-white:focus { color: var(--near-white); }
.hover-white:hover, 
.hover-white:focus { color: var(--white); }

.hover-black-90:hover,
.hover-black-90:focus { color: var(--black-90); }
.hover-black-80:hover,
.hover-black-80:focus { color: var(--black-80); }
.hover-black-70:hover,
.hover-black-70:focus { color: var(--black-70); }
.hover-black-60:hover,
.hover-black-60:focus { color: var(--black-60); }
.hover-black-50:hover,
.hover-black-50:focus { color: var(--black-50); }
.hover-black-40:hover,
.hover-black-40:focus { color: var(--black-40); }
.hover-black-30:hover,
.hover-black-30:focus { color: var(--black-30); }
.hover-black-20:hover,
.hover-black-20:focus { color: var(--black-20); }
.hover-black-10:hover,
.hover-black-10:focus { color: var(--black-10); }
.hover-white-90:hover,
.hover-white-90:focus { color: var(--white-90); }
.hover-white-80:hover,
.hover-white-80:focus { color: var(--white-80); }
.hover-white-70:hover,
.hover-white-70:focus { color: var(--white-70); }
.hover-white-60:hover,
.hover-white-60:focus { color: var(--white-60); }
.hover-white-50:hover,
.hover-white-50:focus { color: var(--white-50); }
.hover-white-40:hover,
.hover-white-40:focus { color: var(--white-40); }
.hover-white-30:hover,
.hover-white-30:focus { color: var(--white-30); }
.hover-white-20:hover,
.hover-white-20:focus { color: var(--white-20); }
.hover-white-10:hover,
.hover-white-10:focus { color: var(--white-10); }
.hover-inherit:hover,
.hover-inherit:focus { color: inherit; }

.hover-bg-black:hover, 
.hover-bg-black:focus { background-color: var(--black); }
.hover-bg-near-black:hover, 
.hover-bg-near-black:focus { background-color: var(--near-black); }
.hover-bg-dark-gray:hover, 
.hover-bg-dark-gray:focus { background-color: var(--dark-gray); }
.hover-bg-mid-gray:hover, 
.hover-bg-mid-gray:focus { background-color: var(--mid-gray); }
.hover-bg-gray:hover, 
.hover-bg-gray:focus { background-color: var(--gray); }
.hover-bg-silver:hover, 
.hover-bg-silver:focus { background-color: var(--silver); }
.hover-bg-light-silver:hover, 
.hover-bg-light-silver:focus { background-color: var(--light-silver); }
.hover-bg-moon-gray:hover, 
.hover-bg-moon-gray:focus { background-color: var(--moon-gray); }
.hover-bg-light-gray:hover, 
.hover-bg-light-gray:focus { background-color: var(--light-gray); }
.hover-bg-near-white:hover, 
.hover-bg-near-white:focus { background-color: var(--near-white); }
.hover-bg-white:hover, 
.hover-bg-white:focus { background-color: var(--white); }
.hover-bg-transparent:hover, 
.hover-bg-transparent:focus { background-color: var(--transparent); }

.hover-bg-black-90:hover,
.hover-bg-black-90:focus { background-color: var(--black-90); }
.hover-bg-black-80:hover,
.hover-bg-black-80:focus { background-color: var(--black-80); }
.hover-bg-black-70:hover,
.hover-bg-black-70:focus { background-color: var(--black-70); }
.hover-bg-black-60:hover,
.hover-bg-black-60:focus { background-color: var(--black-60); }
.hover-bg-black-50:hover,
.hover-bg-black-50:focus { background-color: var(--black-50); }
.hover-bg-black-40:hover,
.hover-bg-black-40:focus { background-color: var(--black-40); }
.hover-bg-black-30:hover,
.hover-bg-black-30:focus { background-color: var(--black-30); }
.hover-bg-black-20:hover,
.hover-bg-black-20:focus { background-color: var(--black-20); }
.hover-bg-black-10:hover,
.hover-bg-black-10:focus { background-color: var(--black-10); }
.hover-bg-white-90:hover,
.hover-bg-white-90:focus { background-color: var(--white-90); }
.hover-bg-white-80:hover,
.hover-bg-white-80:focus { background-color: var(--white-80); }
.hover-bg-white-70:hover,
.hover-bg-white-70:focus { background-color: var(--white-70); }
.hover-bg-white-60:hover,
.hover-bg-white-60:focus { background-color: var(--white-60); }
.hover-bg-white-50:hover,
.hover-bg-white-50:focus { background-color: var(--white-50); }
.hover-bg-white-40:hover,
.hover-bg-white-40:focus { background-color: var(--white-40); }
.hover-bg-white-30:hover,
.hover-bg-white-30:focus { background-color: var(--white-30); }
.hover-bg-white-20:hover,
.hover-bg-white-20:focus { background-color: var(--white-20); }
.hover-bg-white-10:hover,
.hover-bg-white-10:focus { background-color: var(--white-10); }

.hover-dark-red:hover,
.hover-dark-red:focus { color: var(--dark-red); }
.hover-red:hover,
.hover-red:focus { color: var(--red); }
.hover-light-red:hover,
.hover-light-red:focus { color: var(--light-red); }
.hover-orange:hover,
.hover-orange:focus { color: var(--orange); }
.hover-gold:hover,
.hover-gold:focus { color: var(--gold); }
.hover-yellow:hover,
.hover-yellow:focus { color: var(--yellow); }
.hover-light-yellow:hover,
.hover-light-yellow:focus { color: var(--light-yellow); }
.hover-purple:hover,
.hover-purple:focus { color: var(--purple); }
.hover-light-purple:hover,
.hover-light-purple:focus { color: var(--light-purple); }
.hover-dark-pink:hover,
.hover-dark-pink:focus { color: var(--dark-pink); }
.hover-hot-pink:hover,
.hover-hot-pink:focus { color: var(--hot-pink); }
.hover-pink:hover,
.hover-pink:focus { color: var(--pink); }
.hover-light-pink:hover,
.hover-light-pink:focus { color: var(--light-pink); }
.hover-dark-green:hover,
.hover-dark-green:focus { color: var(--dark-green); }
.hover-green:hover,
.hover-green:focus { color: var(--green); }
.hover-light-green:hover,
.hover-light-green:focus { color: var(--light-green); }
.hover-navy:hover,
.hover-navy:focus { color: var(--navy); }
.hover-dark-blue:hover,
.hover-dark-blue:focus { color: var(--dark-blue); }
.hover-blue:hover,
.hover-blue:focus { color: var(--blue); }
.hover-light-blue:hover,
.hover-light-blue:focus { color: var(--light-blue); }
.hover-lightest-blue:hover,
.hover-lightest-blue:focus { color: var(--lightest-blue); }
.hover-washed-blue:hover,
.hover-washed-blue:focus { color: var(--washed-blue); }
.hover-washed-green:hover,
.hover-washed-green:focus { color: var(--washed-green); }
.hover-washed-yellow:hover,
.hover-washed-yellow:focus { color: var(--washed-yellow); }
.hover-washed-red:hover,
.hover-washed-red:focus { color: var(--washed-red); }

.hover-bg-dark-red:hover,
.hover-bg-dark-red:focus { background-color: var(--dark-red); }
.hover-bg-red:hover,
.hover-bg-red:focus { background-color: var(--red); }
.hover-bg-light-red:hover,
.hover-bg-light-red:focus { background-color: var(--light-red); }
.hover-bg-orange:hover,
.hover-bg-orange:focus { background-color: var(--orange); }
.hover-bg-gold:hover,
.hover-bg-gold:focus { background-color: var(--gold); }
.hover-bg-yellow:hover,
.hover-bg-yellow:focus { background-color: var(--yellow); }
.hover-bg-light-yellow:hover,
.hover-bg-light-yellow:focus { background-color: var(--light-yellow); }
.hover-bg-purple:hover,
.hover-bg-purple:focus { background-color: var(--purple); }
.hover-bg-light-purple:hover,
.hover-bg-light-purple:focus { background-color: var(--light-purple); }
.hover-bg-dark-pink:hover,
.hover-bg-dark-pink:focus { background-color: var(--dark-pink); }
.hover-bg-hot-pink:hover,
.hover-bg-hot-pink:focus { background-color: var(--hot-pink); }
.hover-bg-pink:hover,
.hover-bg-pink:focus { background-color: var(--pink); }
.hover-bg-light-pink:hover,
.hover-bg-light-pink:focus { background-color: var(--light-pink); }
.hover-bg-dark-green:hover,
.hover-bg-dark-green:focus { background-color: var(--dark-green); }
.hover-bg-green:hover,
.hover-bg-green:focus { background-color: var(--green); }
.hover-bg-light-green:hover,
.hover-bg-light-green:focus { background-color: var(--light-green); }
.hover-bg-navy:hover,
.hover-bg-navy:focus { background-color: var(--navy); }
.hover-bg-dark-blue:hover,
.hover-bg-dark-blue:focus { background-color: var(--dark-blue); }
.hover-bg-blue:hover,
.hover-bg-blue:focus { background-color: var(--blue); }
.hover-bg-light-blue:hover,
.hover-bg-light-blue:focus { background-color: var(--light-blue); }
.hover-bg-lightest-blue:hover,
.hover-bg-lightest-blue:focus { background-color: var(--lightest-blue); }
.hover-bg-washed-blue:hover,
.hover-bg-washed-blue:focus { background-color: var(--washed-blue); }
.hover-bg-washed-green:hover,
.hover-bg-washed-green:focus { background-color: var(--washed-green); }
.hover-bg-washed-yellow:hover,
.hover-bg-washed-yellow:focus { background-color: var(--washed-yellow); }
.hover-bg-washed-red:hover,
.hover-bg-washed-red:focus { background-color: var(--washed-red); }
.hover-bg-inherit:hover,
.hover-bg-inherit:focus { background-color: inherit; }
