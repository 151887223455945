/* Base typography
========================================================================== */
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
}
h1 {
  font-size: calc(2.4rem + 0.5vw);
  line-height: 1.2;
  text-align: center;
}
h2 {
  font-size: calc(2.2rem + 4.5vw);
  line-height: 1;
  text-align: center;
}
h3 {
  font-size: 3.0rem;
}
h4 {
  color: $secondary-color;
  font-weight: 200;
  font-size: 12px;
  margin-bottom: 2.4rem;
}
h5 {
  font-size: calc(22px + 0.75vw);
}

/* Larger than phablet */
@media (min-width: 550px) {
  h1 {
    font-size: calc(44px + 1.5vw);
    margin-bottom: 0.6rem;
  }
  h2 {
    font-size: calc(36px + 6.5vw);
    line-height: 1;
    text-align: center;
  }
  h3 {
    font-size: 3.6rem;
  }
}

p {
  margin-top: 0;
  margin-bottom: 2.4rem;
}

small{
  color: $secondary-color;
}

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */

sub,
sup {
  font-size: 25%;
  // line-height: 0;
  // position: relative;
  // vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup{
  // font-size: calc(8px);
  margin-left: 5px;
  // margin-right: 10px;
  // top: -10px;
  vertical-align:text-top;
  white-space: nowrap;
}
// .project__title{
//   color: $white;
//   &:hover{
//     color: $secondary-color;
//     sup{
//       color: $white;
//     }
//   }
// }

span{
  font-family: serif;
}

.center{
  text-align: center;
}

em{
  border-bottom: 1px dotted rgb(130, 130, 130);
}

/* Links
========================================================================== */
a {
  text-decoration: none;
  font-family: $font-sans;
  cursor: pointer;
}

a:hover,
a:active,
a:focus{
  // color: gray;
  transition: 0.2s all $ease
}

/* Quotes
========================================================================== */
blockquote{
  font-size: calc(18px + 1vw);
  line-height: 1.3;
  margin: 0 auto;
  padding: 6.4rem 0;
  max-width: 1280px;
}

/* Lists
========================================================================== */
ul {
    list-style-type: none;
    // margin: 6.4rem 0 0 0;
    padding: 0;
    overflow: hidden;
    // font-size: calc(10px + 0.5vw);

    // li{
    //   // font-family: $font-sans;
    //   // color: $white;
    //   float: left;
    //   padding-right: 1.2rem;
    //   text-align: center;
    //
    //   &:last-child{
    //     padding-right: 0;
    //   }
    // }
    //
    // li a {
    //     text-decoration: none;
    //     // border-bottom: $muted-rule;
    //
    //     &:hover{
    //       // color: $white;
    //       // border-bottom: $rule;
    //     }
    // }
}
