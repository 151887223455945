/* General layout
========================================================================== */
body.noscroll{
  overflow: hidden;
}

section {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: calc(100%);
  margin: 0 auto;
  @include breakpoint(sm) {
    flex-direction: row;
  }

  // article{
  //   flex-wrap: wrap;
  //   width: calc(100% - 30px);
  //   margin: 0 auto;
  //   position: fixed;
  //   @include breakpoint(sm) {
  //     max-width: calc(75%);
  //   }
  // }
}

.opener{
  pointer-events: none;
  width: calc(100% - 30px);
  position: fixed;
  @include breakpoint(sm) {
    max-width: calc(75%);
  }
}

.information{
  a{
    transition: $ease;
    border-bottom: 1px dotted rgb(130, 130, 130);
    &:hover{
      color: rgb(84, 180, 119);
    }
  }
}

.project-description{
  padding-bottom: 6rem;
}

.project-description{
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  margin: 0 auto;
  max-width: 1080px;
  @include breakpoint(sm) {
    padding: 160px 20px;
  }
}

.project-info{
  margin-bottom: 4.2rem;
}

.title{
  color: white;
  // position: absolute;
  padding: 15px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  z-index: 3000;
  @media (min-width: 550px){
    padding-top: 14rem;
  }

}


/* Featured projects
========================================================================== */
// #featured{
//     /* Prevent vertical gaps */
//   line-height: 0;
//
//   column-count: 4;
//   column-gap:0px;
//   padding:10px;
//   z-index: 1000;
//   background: white;
//   min-height: calc(100vh);
//   @include breakpoint(sm) {
//   padding: 5%;
//   }

#featured{
  display: flex;
  flex-direction: column;
  // justify-content: space-around;
  // flex-wrap: wrap;
  align-items: flex-start;
  padding: 10px;
  // z-index: 1000;
  // background: white;
  min-height: calc(100vh);
  @include breakpoint(sm) {
    padding: 5%;
    flex-direction: row;
    flex-wrap: wrap;
  }

  a{
    align-self: center;
    @include breakpoint(sm) {
      width: 50%;
    }
    // @include breakpoint(md) {
    //   width: 25%;
    // }
  }

  // figure{
  //   padding-bottom: 20px;
  // }

  img{
    width: 100% !important;
    height: auto !important;
    padding: 10px;
    transition: 0.6s $ease;
    filter: grayscale(100%);
    @include breakpoint(sm) {
      padding: 10%;
    }
    &:hover{
      filter: none;
    }
  }
}

// @media (max-width: 1200px) {
//   #featured {
//   -moz-column-count:    4;
//   -webkit-column-count: 4;
//   column-count:         4;
//   }
// }
// @media (max-width: 1000px) {
//   #featured {
//   -moz-column-count:    3;
//   -webkit-column-count: 3;
//   column-count:         3;
//   }
// }
// @media (max-width: 800px) {
//   #featured {
//   -moz-column-count:    2;
//   -webkit-column-count: 2;
//   column-count:         2;
//   }
// }
// @media (max-width: 400px) {
//   #featured {
//   -moz-column-count:    1;
//   -webkit-column-count: 1;
//   column-count:         1;
//   }
// }

/* Project specific
========================================================================== */
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  @include breakpoint(sm) {
    max-width: calc(1440px);
  }
}

.column {
  width: 100%;
  float: left;
  padding: 10px 0;
  @include breakpoint(sm) {
    padding: 10px;
  }
}

.row::before,
.row::after {
  display: table;
  content: " ";
  clear: both;
}

.one,
.one-third,
.two-thirds,
.one-fourth,
.half,
.one-third-offset,
.two-thirds-offset,
.one-fourth-offset,
.half-offset {
  width: 100%;
}

@include breakpoint(sm) {
  .one {
    width: 100%;
    margin: 0 auto;
  }
  .half {
    width: calc(100% / 2);
  }
  .one-third {
    width: calc(100% / 3);
  }
  .one-fourth {
    width: calc(100% / 4);
  }
  .two-thirds {
    width: calc(100% / 3 * 2);
  }
  .half-offset {
    width: calc(100% / 2);
    margin-left: 50%;
  }
  .one-third-offset {
    width: calc(100% / 3);
    margin-left: 33%;
  }
  .one-fourth-offset {
    width: calc(100% / 4);
    margin-left: 25%;
  }
  .two-thirds-offset {
    width: calc(100% / 3 * 2);
  }
}

.top {
  align-content: flex-start;
}

.middle {
  align-items: center;
  justify-content: space-around;
}

.bottom {
  align-items: flex-end;
}

.start {
  justify-content: flex-start;
  align-items: flex-start;
}



.back-to{
  text-align: center;
  margin: 19.2rem 0;

  // a:hover,
  // a:active,
  // a:focus{
  //   border-bottom: $rule;
  // }
}

.tags{
  padding-top: 2.4rem;
}

/* Images
========================================================================== */
figure{
  display: block;
  width: 100%;
}

figcaption{
  color: $white;
  text-align: center;
}

img{
  display: block;
  margin: auto;
  width: 100%;
}
.project-list{
  width: 100%;
  height: 100%;
  // margin-top: 50vh;
  // padding-bottom: 50vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 2rem;
}

.project-list:hover a {
  opacity: 0.2;
}

.project-list h1:hover a{
  opacity: 1;
}

.project-list h1:hover a:hover{
  opacity: 1;
}

.project{
  h1{
    padding-right: 1rem;
    @include breakpoint(sm){
      padding-right: 2rem;
    }
  }
}


.project .thumnails{
  display: none;
  transition: $smooth;
}

.project:hover .thumnails{
  display: block;
  transition: $smooth;
}


.thumnails{
  height: 100%;
  width: 100%;
  z-index: -99;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: $smooth;
}

.thumnails__wrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  img{
    width: 100%;
    height: auto;
    display: block;
    transition: $smooth;
    filter: grayscale(1);
    padding: 2vh;
    @include breakpoint(sm){
      padding: 5vh;
    }
  }
}

figure[data-size="1"] {
  width: calc(100vw / 12);
}

figure[data-size="2"] {
  width: calc(100vw / 12 * 2);
}

figure[data-size="3"] {
  width: calc(100vw / 12 * 3);
}

figure[data-size="4"] {
  width: calc(100vw / 12 * 4);
}

figure[data-size="5"] {
  width: calc(100vw / 12 * 5);
}

figure[data-size="6"] {
  width: calc(100vw / 12 * 6);
}

figure[data-size="8"] {
  width: calc(100vw / 12 * 8);
}

figure[data-size="10"] {
  width: calc(100vw / 12 * 10);
}

figure[data-size="12"] {
  width: calc(100vw);
}

/* Lazyload images
========================================================================== */
.lazyload,
.lazyloading {
	opacity: 0;
  // transform: translateY(40px);
}
.lazyloaded {
	opacity: 1;
	transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}
.lazyloaded:nth-child(odd){
  transition: transform .85s ease-in-out, opacity .85s ease-in-out;
}
.ratio-container {
    position: relative;
}
.ratio-container:after {
    content: '';
    display: block;
    height: 0;
    width: 100%;
    /* 16:9 = 56.25% = calc(9 / 16 * 100%) */
    padding-bottom: 42.86%;
}
.ratio-container > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
}

.project__item {
  position: relative;
}
.project__item--image{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: $ease;
  // margin: 5% 5% 0% 5%;
  img{
    width: 100%;
    height: auto;
    display: block;
    transform: translateZ(0);
  }
}


footer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
    @media (min-width: 550px){
      padding: 30px;
    }
}

.fade{
  opacity: 1;
}
