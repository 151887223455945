/*! TACHYONS v4.8.1 | http://tachyons.io */

/*
 *
 *      ________            ______
 *      ___  __/_____ _________  /______  ______________________
 *      __  /  _  __ `/  ___/_  __ \_  / / /  __ \_  __ \_  ___/
 *      _  /   / /_/ // /__ _  / / /  /_/ // /_/ /  / / /(__  )
 *      /_/    \__,_/ \___/ /_/ /_/_\__, / \____//_/ /_//____/
 *                                 /____/
 *
 *    TABLE OF CONTENTS
 *
 *    1. External Library Includes
 *       - Normalize.css | http://normalize.css.github.io
 *    2. Tachyons Modules
 *    3. Variables
 *       - Media Queries
 *       - Colors
 *    4. Debugging
 *       - Debug all
 *       - Debug children
 *
 */


/* External Library Includes */
/*@import './_normalize';*/


/* Modules */
@import './_box-sizing';
@import './_aspect-ratios';
@import './_images';
@import './_background-size';
@import './_background-position';
@import './_outlines';
@import './_borders';
@import './_border-colors';
@import './_border-radius';
@import './_border-style';
@import './_border-widths';
@import './_box-shadow';
@import './_code';
@import './_coordinates';
@import './_clears';
@import './_display';
@import './_flexbox';
@import './_floats';
@import './_font-family';
@import './_font-style';
@import './_font-weight';
@import './_forms';
@import './_heights';
@import './_letter-spacing';
@import './_line-height';
@import './_links';
@import './_lists';
@import './_max-widths';
@import './_widths';
@import './_overflow';
@import './_position';
@import './_opacity';
@import './_rotations';
@import './_skins';
@import './_skins-pseudo';
@import './_spacing';
@import './_negative-margins';
@import './_tables';
@import './_text-decoration';
@import './_text-align';
@import './_text-transform';
@import './_type-scale';
@import './_typography';
@import './_utilities';
@import './_visibility';
@import './_white-space';
@import './_vertical-align';
@import './_hovers';
@import './_z-index';
@import './_nested';
@import './_styles';

/* Variables */
/* Importing here will allow you to override any variables in the modules */
@import './_colors';
@import './_media-queries';

/* Debugging */
@import './_debug-children';
@import './_debug-grid';

/* Uncomment out the line below to help debug layout issues */
/* @import './_debug'; */
